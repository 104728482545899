.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 50px 30px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4NDYgMzM4Ij48ZyBjbGlwLXBhdGg9InVybCgjYSkiIGZpbGw9IiNGNEY0RjMiIHN0cm9rZT0iIzIxMkIzRCI+PHBhdGggZD0iTTI4Ny4yNCAyOTEuMzU3Yy0yOC43NDEgMjkuMzA2LTcwLjUzNyA0NC44MDQtMTIwLjgyMSA0NC44MDQtNTAuMjg3IDAtOTIuOTM1LTE1LjM2My0xMjEuNzQyLTQ0LjQzMUMxNS44IDI2Mi42MjguNSAyMjAuMDY2LjUgMTY4LjUxOWMwLTUxLjU0OCAxNS4yNjctOTQuNDE2IDQ0LjE0Mi0xMjMuNTE4QzczLjQxNiAxNi4xMDQgMTE1LjQ4NS44MSAxNjYuNDIuODFjNTAuOTMgMCA5Mi4wOCAxNS41MzEgMTIwLjgyMSA0NC44MzggMjguNzQyIDI5LjI3NCA0My45NCA3MS43NjkgNDMuOTQgMTIyLjg3MiAwIDUxLjEzNy0xNS4xOTggOTMuNTY0LTQzLjk0IDEyMi44MzhaTTM3Ni4zNTcgMjkxLjczdi0uMDAxYy0yOC44NzYtMjkuMTAyLTQ0LjE3Ny03MS42OTgtNDQuMTc3LTEyMy4yMTEgMC01MS41MTQgMTUuMzAxLTk0LjQxNiA0NC4xNzYtMTIzLjUxN0M0MDUuMTMgMTYuMTAzIDQ0Ny4yMzMuODA4IDQ5OC4wOTkuODA4YzI0LjM3OCAwIDQ2LjgzOSAzLjU3NyA2Ni43MDcgMTAuNjJsLjAwMS4wMDEgNy4zNjYgMi42NDZ2MzA4LjcxN2wtNy4zMzQgMi42NDVjLTE5Ljk3IDcuMTQ2LTQyLjQzMSAxMC43NTctNjYuNzQgMTAuNzU3LTUwLjgzMSAwLTkyLjkzNC0xNS4zNjMtMTIxLjc0Mi00NC40NjRaTTczOC4zMTkuNTI4djMzNS45NDZsLTExLjY2OS0uNjJoLS4wMDFjLTQ1LjE3OS0yLjM1LTgyLjk2OC0xNy42MDYtMTA5LjI2NS00NC4xMjRoLS4wMDFjLTI4Ljg3Ni0yOS4wNjgtNDQuMjEtNzEuNjY0LTQ0LjIxLTEyMy4yMTEgMC01MS41NDggMTUuMzAxLTk0LjQxNyA0NC4xNzYtMTIzLjUxOSAyNi4yNjMtMjYuMzggNjQuMDUzLTQxLjYwMiAxMDkuMy00My44ODVsMTEuNjctLjU4N1pNODU4LjAyMiAyMTQuMjgyYzI0LjE3NCAyNS40NzYgNDMuMzQ2IDczLjg4NiA0My40NzcgMTIyLjIxOEg3MzguNXYtMTY4aC4wNzljNDkuNjk4IDAgOTEuMDM1IDE1Ljc3NSAxMTkuNDQzIDQ1Ljc4MlpNNzgyLjc2MiA0NS43MzRDODEyLjMxNCAxNy4wMzYgODUzLjMxLjYxIDkwMy41LjV2MTY3aC0xNjV2LS4wODFjMC01MS43NjIgMjAuNTkzLTk4LjU5OCA0NC4yNjItMTIxLjY4NVoiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBmaWxsPSIjZmZmIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC4wMDIpIiBkPSJNMCAwaDEwMDR2MzM3SDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+");
  background-position: top 50px right;
  background-size: auto calc(100% - 80px);
  background-repeat: no-repeat;

  @media (max-width: 1023px) {
    background: none;
  }

  @media (max-width: 767px) {
    padding: 20px 15px;
  }

  .footer-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid $darkBlue;
    padding: 30px 0 90px;

    @media (max-width: 1023px) {
      padding: 30px 0 0;
    }

    .footer-logo {
      height: 70px;
      margin-bottom: 36px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .footer-address {
      margin: 0 0 28px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }

      p {
        margin: 0;
        @extend .p4;
      }
    }

    .footer-socials {
      display: flex;
      align-items: center;
      gap: 8px;

      a.social-link {
        display: flex;

        svg {
          height: 24px;

          path {
            transition: all 0.2s ease-in-out;
          }
        }

        &:hover {
          svg path {
            fill: $turquoise;
          }
        }
      }
    }
  }
}
