.about-career {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 50px 30px;

  @media (max-width: 767px) {
    padding: 0 15px 30px;
  }

  .career-heading {
    font-family: $fontDegular;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.1em;
    letter-spacing: 0.02em;
    color: $darkBlue;
    margin: 0 0 45px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .career-link {
    @extend .inline-link;
  }
}
