.about-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 126px 50px;

  @media (max-width: 1023px) {
    padding: 80px 50px;
  }

  @media (max-width: 767px) {
    padding: 50px 15px;
  }

  .heading-container {
    width: 100%;
    max-width: calc(((100% - 48px) / 3) * 2 + 24px);
    padding-bottom: 45px;
    border-bottom: 1px solid $darkBlue;
    margin-bottom: 57px;

    @media (max-width: 1023px) {
      max-width: 100%;
    }

    @media (max-width: 767px) {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    .heading {
      margin: 0;
      width: 100%;
      max-width: 80%;

      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }

  .grid-wrapper {
    width: 100%;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(30vw, 1fr));
    grid-auto-rows: 30vw;
    grid-auto-flow: dense;

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .grid-item {
      display: flex;

      &.wide {
        grid-column: span 2;
      }

      &.tall {
        grid-row: span 2;

        @media (max-width: 1023px) {
          grid-column: span 2;
          grid-row: span 4;
        }
      }

      &.large {
        grid-column: span 2;
        grid-row: span 2;
      }

      .item-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
