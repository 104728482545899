// --------------------------------------------------
// Variables
// --------------------------------------------------

// Import Web Fonts
// @import url(...);

// Fonts
$fontDegular: degular-display, sans-serif;
$fontInterface: interface, sans-serif;

// Accents Colors
$darkBlue: #212B3D;
$darkBlue70: #485663;
$lightGrey: #D1D1CF;
$grey50: #D1D1CF;
$grey25: #F4F4F3;
$turquoise: #66CCA9;

$colorAccent1: $turquoise;

// Texts
$dark: $darkBlue;
$grey: $lightGrey;
$light: #FFFFFF;

$colorSuccess: #00966D;
$colorError: #FF564A;
