.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px 45px;
  background-color: $grey25;
  z-index: 50;
  
  @media (max-width: 767px) {
    padding: 15px;
  }
  
  &.small {
    transition: all 0.2s ease-in-out;
    padding: 15px 45px;

    @media (max-width: 767px) {
      padding: 5px 15px;
    }

    .heading-container {
      .header-logo {
        padding: 10px 20px;

        @media (max-width: 900px) {
          padding: 10px;
        }

        svg {
          height: 30px;

          @media (max-width: 900px) {
            height: 20px;
          }
        }
      }

      @media (max-width: 900px) {
        .right-side .mobile-trigger {
          padding: 10px 20px;
        }
      }
    }
  }

  .heading-container {
    width: 100%;
    border: 1px solid $darkBlue;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .header-logo {
      display: flex;
      align-items: center;
      padding: 20px;
      border-right: 1px solid $darkBlue;
      transition: all 0.2s ease-in-out;

      @media (max-width: 900px) {
        padding: 15px;
      }

      svg {
        height: 40px;
        transition: all 0.2s ease-in-out;

        @media (max-width: 900px) {
          height: 32px;
        }
      }
    }

    .right-side {
      display: flex;
      align-items: stretch;

      .nav-link {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.3em;
        letter-spacing: 0.02em;
        color: $darkBlue;
        padding: 0 20px;
        border-left: 1px solid $darkBlue;
        transition: all 0.2s ease-in-out;

        @media (max-width: 900px) {
          display: none;
        }

        &:hover {
          background-color: $darkBlue;
          color: $turquoise;
        }

        &:nth-child(1) {
          padding: 0 70px;
        }

        &:nth-child(2) {
          padding: 0 50px;
        }

        &:nth-child(3) {
          padding: 0 30px;
        }

        &.fr > span:nth-child(2) {
          font-style: italic;
        }
      }

      .mobile-trigger {
        display: none;
        align-items: center;
        padding: 20px;
        border-left: 1px solid $darkBlue;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        @media (max-width: 900px) {
          display: flex;
        }

        svg {
          height: 24px;
        }
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  opacity: 0;
  display: flex;
  flex-direction: column;
  background-color: $grey25;
  z-index: 6;
  padding: 15px 45px 30px;
  pointer-events: none;

  @media (max-width: 767px) {
    padding: 15px 15px 30px;
  }

  &.is-active {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(-50%);
    transition: all 0.4s ease-in-out;
  }

  .top-line {
    width: 100%;
    display: flex;
    border: 1px solid $darkBlue;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 30px;

    .header-logo {
      display: flex;
      align-items: center;
      padding: 15px;
      border-right: 1px solid $darkBlue;

      svg {
        height: 32px;
      }
    }

    .mobile-trigger {
      display: flex;
      align-items: center;
      padding: 20px;
      border-left: 1px solid $darkBlue;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      svg {
        height: 24px;
      }
    }
  }

  .main-container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .lang-switcher-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 90px;

      .nav-link {
        font-family: $fontDegular;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1em;
        letter-spacing: 0.02em;
        color: $darkBlue;
      }
    }

    .nav-links {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;

      .nav-link {
        width: 100%;
        font-family: $fontDegular;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 1em;
        letter-spacing: 0.02em;
        color: $darkBlue;
        padding-bottom: 15px;
        border-bottom: 1px solid $darkBlue;

        &.fr > span:nth-child(2) {
          font-style: italic;
        }
      }
    }
  }

  .footer-socials {
    display: flex;
      align-items: center;
      gap: 8px;

      a.social-link {
        display: flex;

        svg {
          height: 24px;
        }
      }
  }
}
