.button {
  font-family: $fontInterface;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4em;
  padding: 0 25px 4px;
  color: $darkBlue;
  background-color: $lightGrey;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;
  appearance: none;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $darkBlue70;
    color: $turquoise;
  }
}

.inline-link {
  position: relative;
  font-family: $fontInterface;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4em;
  color: $darkBlue;
  padding-right: 24px;
  transition: all 0.2s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PG1hc2sgaWQ9ImEiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iI0Q5RDlEOSIgZD0iTTAgMGgyMHYyMEgweiIvPjwvbWFzaz48ZyBtYXNrPSJ1cmwoI2EpIj48cGF0aCBkPSJtNS4zMzUgMTQuOTk3LTEuMTY3LTEuMTY2IDgtOEg1LjAwMVY0LjE2NGgxMHYxMGgtMS42NjZWNi45OTdsLTggOFoiIGZpbGw9IiMyMTJCM0QiLz48L2c+PC9zdmc+");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    color: $turquoise;

    &:after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PG1hc2sgaWQ9ImEiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iI0Q5RDlEOSIgZD0iTTAgMGgyMHYyMEgweiIvPjwvbWFzaz48ZyBtYXNrPSJ1cmwoI2EpIj48cGF0aCBkPSJtNS4zMzUgMTQuOTk3LTEuMTY3LTEuMTY2IDgtOEg1LjAwMVY0LjE2NGgxMHYxMGgtMS42NjZWNi45OTdsLTggOFoiIGZpbGw9IiM2NkNDQTkiLz48L2c+PC9zdmc+");
    }
  }

  &.light {
    color: $grey25;

    &:after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PG1hc2sgaWQ9ImEiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iI0Q5RDlEOSIgZD0iTTAgMGgyMHYyMEgweiIvPjwvbWFzaz48ZyBtYXNrPSJ1cmwoI2EpIj48cGF0aCBkPSJtNS4zMzMgMTQuOTk3LTEuMTY3LTEuMTY2IDgtOEg0Ljk5OVY0LjE2NGgxMHYxMGgtMS42NjZWNi45OTdsLTggOFoiIGZpbGw9IiNGNEY0RjMiLz48L2c+PC9zdmc+");
    }
  }
}
