.about-news {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 50px 126px;

  @media (max-width: 767px) {
    padding: 0 15px 80px;
  }

  .about-heading {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid $darkBlue;
    margin-bottom: 45px;

    @media (max-width: 767px) {
      padding-bottom: 15px;
      margin-bottom: 30px;
    }

    &.fr > span:nth-child(2) {
      font-style: italic;
    }
  }

  .about-articles {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 26px;

    .article-item {
      width: calc((100% - 52px) / 3);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: $darkBlue;

      @media (max-width: 1023px) {
        width: calc((100% - 26px) / 2);
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      .item-image {
        width: 100%;
      }

      .article-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 32px;

        .article-text {
          @extend .p4;
          color: $turquoise;
          margin: 0 0 40px;
        }

        .article-link {
          @extend .inline-link;
          @extend .light;
        }
      }
    }
  }
}
