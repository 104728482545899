.home-about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 66px 50px 125px;

  @media (max-width: 767px) {
    padding: 60px 15px;
  }

  .about-heading {
    width: 100%;
    max-width: 890px;
    font-family: $fontDegular;
    font-style: normal;
    font-weight: 600;
    font-size: 128px;
    line-height: 1em;
    letter-spacing: 0.02em;
    color: $darkBlue;
    margin: 0 0 45px;
    padding-bottom: 45px;
    border-bottom: 1px solid $darkBlue;

    @media (max-width: 767px) {
      font-size: 72px;
      line-height: .9em;
      margin-bottom: 15px;
      padding: 0;
      border: none;
    }
  }

  .about-text {
    width: 100%;
    max-width: 800px;
    @extend .p1;
    margin: 0;
  }
}
