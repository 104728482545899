.project-hero {
  position: relative;
  width: 100%;
  height: calc(100vh - 122px);
  display: flex;

  @media (max-width: 767px) {
    height: 80vh;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: #212B3D;
    opacity: 0.25;
    z-index: 2;
  }

  .project-thumnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .hero-content {
    position: absolute;
    z-index: 3;
    bottom: 126px;
    left: 50px;
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;

    @media (max-width: 1240px) {
      bottom: 50px;
    }

    @media (max-width: 767px) {
      left: 15px;
      bottom: 80px;
      width: calc(100% - 30px);
    }

    .hero-heading {
      color: $light;
      margin: 0 0 10px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 1em;
      }
    }

    .hero-tags {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 12px;

      .hero-tag {
        @extend .button;
      }
    }
  }

  .play-trigger {
    position: absolute;
    z-index: 4;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    cursor: pointer;

    @media (max-width: 1240px) {
      top: 30%;
    }

    @media (max-width: 767px) {
      top: 40%;
    }

    svg {
      height: 115px;
    }
  }

  .video-iframe {
    display: none;
  }
}
