.about-hero {
  width: 100%;
  height: calc(100vh - 122px);
  display: flex;

  @media (max-width: 1023px) {
    height: auto;
  }

  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
